import React,{ useEffect, useState } from 'react';
import PropTypes                     from 'prop-types';
import { useT }                      from '@transifex/react';
import { MatrixShows }               from './components/Shows';
import { MatrixCheckpoints }         from './components/Checkpoints';

import './style.css';

const Matrix = (props) =>
{
    const t = useT();
    const [matrixContainerWidth, setmatrixContainerWidth] = useState(null); 

    useEffect(() => {
        const matrixContainerWidth = window.innerWidth - 500;
        setmatrixContainerWidth(matrixContainerWidth);
    }, []);

    return (
        <div className="matrix-container" style={{'width':matrixContainerWidth,'maxWidth':matrixContainerWidth}}>
            <div className="matrix">
                <div className="matrix-col matrix-col--fixed-left">
                    <div className="matrix-item matrix-item--header">
                        <div className="section">
                            {t("Checkpoints")}
                        </div>
                    </div>
                    <div className="matrix-item matrix-item--device">
                        <div className="section">
                            {t("Devices")} ({props.matrixData.totalNumberOfDevices})
                        </div>
                    </div>
                    <MatrixShows
                        shows={props.matrixData.shows}
                        activateShowAndTicketTypeAll={props.activateShowAndTicketTypeAll}
                        deactivateShowAndTicketTypeAll={props.deactivateShowAndTicketTypeAll}
                    />
                </div>
                <MatrixCheckpoints
                    checkpoints={props.matrixData.checkpoints}
                    activateOrDeactivateDevice={props.activateOrDeactivateDevice}
                    activateShowAndTicketTypeAll={props.activateShowAndTicketTypeAll}
                    deactivateShowAndTicketTypeAll={props.deactivateShowAndTicketTypeAll}
                />
            </div>
        </div>
    );
};

Matrix.propTypes = {
    matrixData                 : PropTypes.object.isRequired,
    activateShowAndTicketTypeAll          : PropTypes.func.isRequired,
    deactivateShowAndTicketTypeAll        : PropTypes.func.isRequired,
    activateOrDeactivateDevice : PropTypes.func.isRequired
};

export default Matrix;