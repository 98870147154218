import React, { Component }  from 'react';
import { T, useT }           from '@transifex/react';
import { Redirect }          from 'react-router-dom';
import Panel                 from '../../../../themes/default/Layout/components/Panel/Panel';
import { SceneTitle }        from '../../../../themes/default/Title/components/Scene';
import { VenueService }      from '../../../../services/api/Venue';
import { CheckpointService } from '../../../../services/api/Checkpoint';
import Dialog                from '../../../../themes/default/Dialog/Dialog';
import { DialogError }       from '../../../../themes/default/Dialog';
import { Input }             from '../../../../themes/default/Form/components/Input';
import { HandheldService }   from '../../../../services/api/Handheld';
import { ResponseService }   from '../../../../services/utils/Response';
import { Info }              from './components/Info';
import { Switch }            from '../../../../themes/default/Form/components/Switch';
import {
    ValidatorService,
    NotBlank,
    MinLength,
    MaxLength,
    Type }                   from 'pretty-validator'
import { LoadingScreen }     from '../../../../components/Loading';
import { ZoneService }       from '../../../../services/api/Zone';
import { Matrix }            from './components/Matrix';
import { MatrixService }     from './services/Matrix';

import './style.css'

const SHOW_TIME_RANGE_ALL = '1';
const SHOW_TIME_RANGE_TIME_FRAME = '3';

/**
 * @class scenes/VenueDashboard/VenueDashboard
 */
class VenueDashboard extends Component
{
    /**
     * @type {Object}
     */
    validatorService;

    /**
     * @type {Object}
     */
    venueService;

    /**
     * @type {Object}
     */
    responseService;

    /**
     * @type {Object}
     */
    matrixService;

    state = {
        venue: {},
        venueNewName: null,
        showDeleteConfirmationDialog: false,
        showEditVenueDialog: false,
        venueNameErrors: '',
        isAutoActivated: null,
        showsPeriodValue: null,
        checkpoints: [],
        showLoadingScreen: false,
        shows: [],
        redirectToVenueManagementPage: false,
        showErrorDialog: false,
        errorMessages: [],
        isVenueIsolated: null
    };

    /**
     * @override
     */
    constructor() {
        super();

        this.venueService = new VenueService();
        this.checkpointsService = new CheckpointService();
        this.validatorService = new ValidatorService();
        this.handheldService = new HandheldService();
        this.responseService = new ResponseService();
        this.globalZoneService = new ZoneService();
        this.globalCheckpointsService = new CheckpointService();
        this.matrixService = new MatrixService();
    }

    /**
     * @override
     */
    componentDidMount ()
    {
        this.venueId = this.props.match.params.id;

        this.handleCheckpoints(this.venueId);
    }

    /**
     * Retrieves appropriate venue according to venue id.
     *
     * @param {String} venueId
     */
    handleVenue = (venueId) =>
    {
        this.setState({
            showLoadingScreen: true
        });
        this.venueService.getOne(venueId).then(response => {
            if (response.status === 200) {
                if (JSON.stringify(response.data) !== JSON.stringify(this.state.venue)) {
                    this.setState(
                        {
                            venue: response.data,
                            showsPeriodValue: response.data.useTimeframe ? SHOW_TIME_RANGE_TIME_FRAME : SHOW_TIME_RANGE_ALL
                        }, () => {
                            this.createDefaultZoneAndCheckpoint();
                            this.handleShows(this.venueId);
                        }
                    )
                }
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        });
    };

    UNSAFE_componentWillReceiveProps(props){

        props.venue &&
        this.setState({
            venue: props.venue,
            showsPeriodValue: props.venue.useTimeframe ? SHOW_TIME_RANGE_TIME_FRAME : SHOW_TIME_RANGE_ALL
            }, () => {
                this.createDefaultZoneAndCheckpoint();
                this.handleShows(this.venueId);
        })
    }

    /**
     * Creates default Zone `Public Area`.
     */
    createDefaultZoneAndCheckpoint = () => {
        this.globalZoneService.getAll(this.venueId).then(response => {
            if (response.status === 200 && response.data.totalResultCount === 0) {
                let defaultZoneName = 'Public Area';
                this.setState({
                    showLoadingScreen: true
                });
                this.globalZoneService.createOne(this.venueId,{name: defaultZoneName})
                .then(response => {
                    if (response.status === 201) {
                        this.createDefaultCheckpoint(defaultZoneName);
                    } else {
                        this.setState({
                            showErrorDialog: true,
                            errorMessages: this.responseService.getErrorMessages(response.data)
                        });
                    }
                });
            };
            this.setState({
                showLoadingScreen: false
            });
        })
    };

    /**
     * Creates default checkpoint.
     *
     * @param {String} zoneName
     * @param {String} checkpointName
     */
    createDefaultCheckpoint = (zoneName, checkpointName = 'Main Entry') =>
    {
        this.setState({
            showLoadingScreen: true
        });
        this.globalCheckpointsService.createOne(
            this.venueId,
            {
                name: checkpointName,
                from: null,
                to: zoneName,
                permissionCheckOnly: false
            }
        ).then(response=> {
            if (response.status !== 201) {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        })
    };

    /**
     * Retrieves all checkpoints of certain venue according to venue id.
     *
     * @param {String} venueId
     */
    handleCheckpoints = (venueId) =>
    {
        this.setState({
            showLoadingScreen: true
        });
        this.checkpointsService.getAll(venueId).then(response => {
            if (response.status === 200) {
                if (JSON.stringify(response.data.results) !== JSON.stringify(this.state.checkpoints)) {
                    this.setState({
                        checkpoints: response.data.results
                    });
                }
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        });
    };

    /**
     * Retrieves all shows of certain venue according to venue id.
     *
     * @param {String} venueId
     */
    handleShows = (venueId) =>
    {
        this.setState({
            showLoadingScreen: true
        });

        let dateRange = 'all';

        if (this.state.showsPeriodValue === SHOW_TIME_RANGE_TIME_FRAME) {
            dateRange = 'timeframe';
        }

        let parameterObject = {
            Sort: 'begin',
            dateRange: dateRange,
            pageSize: 9999
        };

        this.venueService.getAllShows(venueId, parameterObject).then(response => {
            if (response.status === 200) {
                if (
                    response.data.results !== null &&
                    JSON.stringify(response.data.results) !== JSON.stringify(this.state.shows)
                ) {
                    this.setState(
                        {
                            shows: response.data.results,
                        }
                    )
                }
            } else {
                this.setState({
                    showErrorDialog: true,
                    errorMessages: this.responseService.getErrorMessages(response.data)
                });
            }
            this.setState({
                showLoadingScreen: false
            });
        });
    };

    /**
     * Creates show activations object.
     *
     * @param {Object} handheldShows
     * @param {String} showId
     * @param {Boolean} value
     *
     * @returns {Object}
     */
    createShowActivationsObject = (handheldShows, showId, value) =>
    {
        let configObject = {};
        configObject['showActivations'] = {};
        Object.entries(handheldShows).map(handheldShow => {
            let isActive = false;
            if (handheldShow[0] === showId) {
                configObject['showActivations'][handheldShow[0]] = value;
            } else {
                let currentActive = handheldShow[1].reportedValue || handheldShow[1].adminRequestedValue;
                if (handheldShow[1].forcedActive === true) {
                    isActive = true;
                } else {
                    if (handheldShow[1].latestAdminValueApplied === true) {
                        isActive = handheldShow[1].reportedValue;
                    } else {
                        isActive = handheldShow[1].adminRequestedValue;
                    }
                }
                if (currentActive !== isActive) {
                    configObject['showActivations'][handheldShow[0]] = isActive;
                }
            }

            return true;
        });

        return configObject['showActivations'];
    };

    /**
     * Creates tickettype activations object.
     *
     * @param {Object} handheldShows
     * @param {String} showId
     * @param {String} ticketTypeId
     * @param {Boolean} value
     * @param {Boolean} hasTicketTypeActivationImplemented
     *
     * @returns {Object}
     */
    createTicketTypeActivationsObject = (handheldShows, showId, ticketTypeId, value, hasTicketTypeActivationImplemented) =>
    {
        let configObject = {};
        configObject['ticketTypeActivations'] = {};
        Object.entries(handheldShows).map(handheldShow => {
            let ticketTypes = this.state.shows.find((element) => element.id == handheldShow[0])?.ticketTypes;
            let ticketTypeObjects = {};
            ticketTypes && ticketTypes.forEach(ticketType => {
                let ticketTypeActivation = handheldShow[1].ticketTypeActivations.find((element) => element.id == ticketType.id);
                let isActive = (handheldShow[1].latestAdminValueApplied ? ticketTypeActivation?.reportedValue : ticketTypeActivation?.adminRequestedValue) ?? false;
                if (hasTicketTypeActivationImplemented && handheldShow[0] === showId) {
                    if (!ticketTypeId || ticketType.id === ticketTypeId) {
                        isActive = value;
                    }
                    ticketTypeObjects[ticketType.id] = isActive;
                }
            });
            if (Object.keys(ticketTypeObjects).length > 0) {
                configObject['ticketTypeActivations'][handheldShow[0]] = ticketTypeObjects;
            }
        });

        return configObject['ticketTypeActivations'];
    };

    /**
     * Sends request and updates device configuration.
     *
     * @param {String} checkpointName
     * @param {String} handheldId
     * @param {String} showId
     * @param {String} ticketTypeId
     * @param {Boolean} value
     */
    updateDeviceConfig = (checkpointName, handheldId, showId, ticketTypeId, value) =>
    {
        return new Promise((resolve, reject) => {
            this.setState({
                showLoadingScreen: true
            });
            this.handheldService.getOne(handheldId).then(response => {
                if (response.status === 200) {
                    let configObject = {};
                    let handheld = this.state.venue.handhelds[handheldId];
                    let handheldShows = handheld.shows;
                    let handheldShow = Object.entries(handheldShows).find((element) => element[0] == showId);
                    // Deactivate show if all ticket types are deactivated
                    let forceDeactivateShow = ticketTypeId && !value && handheldShow[1].ticketTypeActivations.find((element) => element.id !== ticketTypeId && element.adminRequestedValue === true) === undefined;
                    let volume = response.data.state.volume.latestAdminValueApplied ?
                        response.data.state.volume.reportedValue :
                        response.data.state.volume.adminRequestedValue;
                    let brightness = response.data.state.brightness.latestAdminValueApplied ?
                        response.data.state.brightness.reportedValue :
                        response.data.state.brightness.adminRequestedValue;
                    configObject['volume'] = volume;
                    configObject['brightness'] = brightness;
                    configObject['checkpoint'] = checkpointName;
                    // Check if handheld has ticket type activation implemented
                    let hasTicketTypeActivationImplemented = this.matrixService.hasTicketTypeActivationImplemented(handheld);
                    // Show is automatically activated if one ticket type is activated and deactivated if all ticket types are deactivated
                    configObject['showActivations'] = this.createShowActivationsObject(handheldShows, showId, forceDeactivateShow ? false : (ticketTypeId ? true : value));
                    configObject['ticketTypeActivations'] = this.createTicketTypeActivationsObject(handheldShows, showId, ticketTypeId, value, hasTicketTypeActivationImplemented);
    
                    this.handheldService.updateConfiguration(handheldId, configObject).then(response => {
                        if (response.status === 204) {
                            this.handleVenue(this.state.venue.id);
                            resolve();
                        } else {
                            this.setState({
                                showErrorDialog: true,
                                errorMessages: this.responseService.getErrorMessages(response.data)
                            });
                            reject("Error updating configuration");
                        }
                        this.setState({
                            showLoadingScreen: false,
                        });
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    this.setState({
                        showErrorDialog: true,
                        errorMessages: this.responseService.getErrorMessages(response.data),
                        showLoadingScreen: false
                    });
                    reject("Error fetching handheld");
                }
            }).catch(error => {
                this.setState({ showLoadingScreen: false });
                reject(error);
            });
        });
    };

    /**
     * Activates or deactivates device for certain show or tickettype on certain checkpoint.
     *
     * @param {Boolean} value
     * @param {String} name
     */
    activateOrDeactivateDeviceForShowAndTicketType = async (value, name) =>
    {
        const [showId, ticketTypeId, handheldId, checkpointName] = name.split('/');

        await this.updateDeviceConfig(checkpointName, handheldId, showId, ticketTypeId, value);
    };
    
    /**
     * Activates or deactivates all devices at all checkpoints for show/tickettype according to passed action.
     *
     * @param {String} action
     * @param {String} showId
     * @param {String} ticketTypeId
     * @param {String} selectedCheckpoint
     */
    showAndTicketTypeActivation = async (action, showId, ticketTypeId, selectedCheckpoint = null) =>
    {
        let value = null;
        if (action === 'activate') {
            value = true;
        } else if (action === 'deactivate') {
            value = false;
        }
        for (const handheld of Object.entries(this.state.venue.handhelds)) {
            if (handheld[1].stagingStatus === 'staged') {
                for (const checkpoint of this.state.checkpoints) {
                    if (
                        (handheld[1].checkpoint.latestAdminValueApplied && handheld[1].checkpoint.reportedValue === checkpoint.name) ||
                        (!handheld[1].checkpoint.latestAdminValueApplied && handheld[1].checkpoint.adminRequestedValue === checkpoint.name)
                    ) {
                        if (Object.keys(handheld[1].shows).includes(showId)) {
                            if (!selectedCheckpoint || selectedCheckpoint === checkpoint.name) {
                                await this.updateDeviceConfig(checkpoint.name, handheld[0], showId, ticketTypeId, value);
                            }
                        }
                    }
                }
            }
        }
    };

    /**
     * Activates all devices at all checkpoints for certain show/ticketype.
     *
     * @param {String} showId
     * @param {String} ticketTypeId
     * @param {String} checkpoint
     */
     activateShowAndTicketTypeAll = async (showId, ticketTypeId, checkpoint = null) =>
     {
         await this.showAndTicketTypeActivation('activate', showId, ticketTypeId, checkpoint);
     };
 
     /**
      * Deactivates all devices at all checkpoints for certain show/tickettype.
      *
      * @param {String} showId
      * @param {String} ticketTypeId
      * @param {String} checkpoint
      */
     deactivateShowAndTicketTypeAll = async (showId, ticketTypeId, checkpoint = null) =>
     {
         await this.showAndTicketTypeActivation('deactivate', showId, ticketTypeId, checkpoint);
     };

    /**
     * Checks if venue name is valid and if not adds error to input field.
     *
     * @returns {Boolean}
     */
    isValidVenueName = () =>
    {
        let errors = this.validatorService.validate(this.state.venue.name, [
            new NotBlank(), new MinLength(3), new MaxLength(50), new Type('string')
        ]);

        let isValid = errors.length === 0;

        if (!isValid) {
            this.setState({
                venueNameErrors: errors
            });
        }

        return isValid
    };

    /**
     * Updates state value responsible for showing edit venue modal.
     */
    closeEditVenueModal = () =>
    {
        this.setState({
            isAutoActivated: null,
            venueNameErrors: '',
            showEditVenueDialog: false,
            isVenueIsolated: null,
            venueNewName: null
        });
    };

    /**
     * Edits venue.
     */
    editVenue = () =>
    {
        const {isAutoActivated, isVenueIsolated, venue, venueNewName } = this.state;
        if ( this.isValidVenueName() ) {
            this.setState({
                showLoadingScreen: true
            });
            let oldVenue = {...venue};
            this.venueService.updateOne(
                this.venueId,
                {
                    ...oldVenue,
                    name: venueNewName ? venueNewName : venue.name,
                    archived: venue.archived,
                    autoActivateShows: isAutoActivated ? true : false,
                    isolated: isVenueIsolated !== null ? isVenueIsolated :  venue.isolated,
                }
            ).then(response => {
                    if (response.status === 204) {
                        this.handleVenue(this.venueId);
                    } else {
                        this.setState({
                            showErrorDialog: true,
                            errorMessages: this.responseService.getErrorMessages(response.data)
                        });
                    }
                    this.setState({
                        showLoadingScreen: false
                    });
                }
            );
            this.closeEditVenueModal();
        }
    };

    /**
     * Updates state and opens confirmation dialog.
     *
     * @param {String} venueName
     */
    openEditVenueDialog = (venueName) =>
    {
        this.setState({
            showEditVenueDialog: true
        });
    };

    /**
     * Updates the state of component based on switch position.
     */
    handleActivateShowsClick = () =>
    {
        this.setState((prevState) => ({ isAutoActivated: !prevState.isAutoActivated }));
    };

    /**
     * Updates the state of component based on switch position.
     */
    handleVenuIsolationClick = () =>
    {
        this.setState((prevState) => ({ isVenueIsolated: !prevState.isVenueIsolated }));
    }

    /**
     * Updates state, closes delete confirmation dialog and redirects to venue management page.
     */
    closeDeleteConfirmationDialog = () =>
    {
        this.setState({
            showDeleteConfirmationDialog: false
        });
    };

    /**
     * Removes Venue.
     */
    removeVenue = () => {
        this.venueService.deleteOne(this.venueId).then(response => {
                if (response.status === 204) {
                    this.setState({
                        redirectToVenueManagementPage: true
                    }, this.closeDeleteConfirmationDialog());
                } else {
                    this.setState({
                        showErrorDialog: true,
                        errorMessages: this.responseService.getErrorMessages(response.data)
                    });
                }
            }
        );
    };

    /**
     * Updates state and opens delete dialog.
     *
     * @param {String} venueName
     */
    openDeleteConfirmationDialog = () =>
    {
        this.setState({
            showDeleteConfirmationDialog: true
        });
    };

    /**
     * Updates state and closes error dialog.
     */
    closeErrorDialog = () =>
    {
        this.setState({
            showErrorDialog : false
        });
    };

    /**
     * @returns {XML}
     */
    render()
    {
        const matrixData = this.matrixService.getDashboardMatrixData(this.state.venue, this.state.shows, this.state.checkpoints, this.state.isTest);

        return (
            <div className="VenueDashboard">
                
                <Tite name={this.state.venue?.name}/>
                
                <Panel className="edit remove" title={this.state.venue.name} colorize
                       removeHandler={this.openDeleteConfirmationDialog}
                       editHandler={this.openEditVenueDialog}>

                {/* Renders dashboard matrix if certain conditions are met otherwise Info component will be rendered. */}
                { this.state.checkpoints && this.state.shows && matrixData.totalNumberOfDevices
                    ?   <Matrix matrixData={matrixData}
                            activateShowAndTicketTypeAll={this.activateShowAndTicketTypeAll}
                            deactivateShowAndTicketTypeAll={this.deactivateShowAndTicketTypeAll}
                            activateOrDeactivateDevice={this.activateOrDeactivateDeviceForShowAndTicketType}/>
                    :   <Info/>
                }

                </Panel>

                <EditDialog
                    venue={this.state.venue}
                    receiveValue={venueNewName => this.setState({venueNewName})}
                    editVenue={this.editVenue}
                    isAutoActivated={this.state.isAutoActivated}
                    isVenueIsolated={this.state.isVenueIsolated}
                    closeEditVenueModal={this.closeEditVenueModal}
                    venueNameErrors={this.state.venueNameErrors}
                    showEditVenueDialog={this.state.showEditVenueDialog}
                    handleActivateShowsClick={this.handleActivateShowsClick}
                    handleVenuIsolationClick={this.handleVenuIsolationClick}
                />

                {this.state.showDeleteConfirmationDialog && <DeleteConfirmationDialog
                    removeVenue                   ={() => this.removeVenue()}
                    closeDeleteConfirmationDialog ={this.closeDeleteConfirmationDialog}
                    showDeleteConfirmationDialog  ={this.state.showDeleteConfirmationDialog}
                />}
                
                { this.state.redirectToVenueManagementPage ? <Redirect to={'/'} /> : null }
                { this.state.showLoadingScreen ? <LoadingScreen/> : null }
                
                <ErrorDialog
                    closeErrorDialog={this.closeErrorDialog}
                    errorMessages={this.state.errorMessages}
                    showErrorDialog={this.state.showErrorDialog}
                />
            </div>
        );
    }
};

const Tite = ({ name }) => {
    const t = useT();
    return (
        <SceneTitle text={t("Venue Dashboard")} >
            <p>{name}</p>
        </SceneTitle>
    )
};

// Renders edit dialog.
const EditDialog = ({showEditVenueDialog, isAutoActivated, venue, isVenueIsolated, editVenue, receiveValue, venueNameErrors, handleActivateShowsClick, handleVenuIsolationClick, closeEditVenueModal }) =>
{
    const venuChecked = isVenueIsolated !== null ? isVenueIsolated : venue.isolated;
    const showChecked = isAutoActivated !== null ? isAutoActivated : venue.autoActivateShows;

    return (showEditVenueDialog &&
        <Dialog
            action={editVenue}
            mainButton={<T _str="Update"/>}
            title={<T _str="Edit venue"/>}
            showModal={ showEditVenueDialog }
            closeModal={ closeEditVenueModal }
        >
            <Input
                value={venue.name}
                name="Venue name"
                getValue={receiveValue}
                label={<T _str="New Venue Name"/>}
                className={ venueNameErrors.length > 0 ? 'error' : ''} focus
            />
            <div className="row mb-2 switch-padding-top">
                <div className="col-6">
                    <T _str="Auto Activate Shows"/>
                </div>
                <div className="col-6 right-position">
                    <Switch name="isAutoActivated" label="" checked={ showChecked } getValue={handleActivateShowsClick} />
                </div>
            </div>
            <div className="row switch-padding-top">
                <div className="col-6">
                    <T _str="Venue Isolation"/>
                </div>
                <div className="col-6 right-position">
                    <Switch name="isVenueIsolation" label="" checked={ venuChecked } getValue={handleVenuIsolationClick} />
                </div>
            </div>
            { venueNameErrors.length > 0 && venueNameErrors.map( (error, key) =>
                <div key={ key } className="error">
                    - { error }
                </div>
            )}
        </Dialog>
    );
};

// Renders confirmation dialog.
const DeleteConfirmationDialog = ({showDeleteConfirmationDialog, removeVenue, closeDeleteConfirmationDialog }) =>
(
    <Dialog
        action={removeVenue}
        mainButton={<T _str="Remove"/> }
        title={<T _str="Confirmation dialog"/>}
        showModal={showDeleteConfirmationDialog}
        closeModal={ closeDeleteConfirmationDialog }
    >
        <h4 className="text-center"><T _str="Are you sure you want to remove this venue?"/></h4>
    </Dialog>
);

// Renders error diagram if an error occur
const ErrorDialog = ({showErrorDialog, closeErrorDialog, errorMessages }) =>
(
    <DialogError show={showErrorDialog} closeDialog={closeErrorDialog}>
        {
            errorMessages.map((message,index) => <p key={index}>{message}</p>)
        }
    </DialogError>
);

export default VenueDashboard;