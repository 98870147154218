import React,{ Component } from 'react';
import PropTypes           from 'prop-types';
import { T }               from '@transifex/react';
import { DateTimeService } from '../../../../../../../../services/utils/DateTime';

import IconMore from './images/ico-more.svg';

/**
 * @class ./scenes/VenueManagement/scenes/VenueDashboard/components/Matrix/components/Shows/Shows
 */
class Shows extends Component
{
    /**
     * @type {Object}
     */
    dateTimeService;

    /**
     * Constructor.
     *
     * @param {Object} props
     */
    constructor(props)
    {
        super(props);

        this.dateTimeService = new DateTimeService();
        this.state = {
            baseTopValue: 0,
            itemBaseTopValue: 0,
        }
    };

    /**
     * @override
     */
    componentDidMount ()
    {
        const matrixItem = document.createElement('div');
        matrixItem.style.display = 'none';
        matrixItem.classList.add('matrix-item');

        const subsection = document.createElement('div');
        subsection.classList.add('subsection');
        
        const optionsMenu = document.createElement('div');
        optionsMenu.classList.add('options-menu');
        
        const ticketType = document.createElement('div');
        ticketType.classList.add('tickettype');
        
        const ticketTypeItem = document.createElement('div');
        ticketTypeItem.classList.add('tickettype-item');
        
        const ticketTypeOptionsMenu = document.createElement('div');
        ticketTypeOptionsMenu.classList.add('options-menu');
        
        ticketTypeItem.appendChild(ticketTypeOptionsMenu);
        ticketType.appendChild(ticketTypeItem);
        subsection.appendChild(ticketType);
        subsection.appendChild(optionsMenu);
        matrixItem.appendChild(subsection);
        document.body.appendChild(matrixItem);

        const baseComputedStyle = window.getComputedStyle(optionsMenu);
        const baseTopValue = parseInt(baseComputedStyle.getPropertyValue('top').trim(), 10);
    
        const itemComputedStyle = window.getComputedStyle(ticketTypeOptionsMenu);
        const itemBaseTopValue = parseInt(itemComputedStyle.getPropertyValue('top').trim(), 10);
        document.body.removeChild(matrixItem);

        this.setState({ baseTopValue, itemBaseTopValue });
    }

    /**
     * @returns {XML}
     */
    render()
    {
        return (
            this.props.shows.map((show, showIndex) => {
                return (
                    <div className="matrix-item" key={showIndex}>
                        {
                            showIndex === 0 &&
                            <div className="section"><T _str="Shows on devices"/></div>
                        }
                        <div className="subsection">
                            <span className="show-name-and-date">
                                {this.dateTimeService.replaceYearAndDate(this.dateTimeService.getLocalDateTime(show.begin))}
                                <span>{show.name}</span>
                            </span>
                            <span className="pull-right">
                                <img src={IconMore} alt="More" className="pull-right"/>
                            </span>
                            <div className="options-menu">
                                <ul>
                                    <li onClick={() => this.props.activateShowAndTicketTypeAll(show.id)}><T _str="Activate show at all"/></li>
                                    <li onClick={() => this.props.deactivateShowAndTicketTypeAll(show.id)}><T _str="Deactivate show at all"/></li>
                                </ul>
                            </div>
                            <div className={` ${show.ticketTypes.length > 0 ? "tickettype" : ""}`}>
                                {show.ticketTypes.map((ticketType, ticketTypeIndex) => {

                                    const topOffSet = this.state.baseTopValue + ((ticketTypeIndex + 1) * this.state.itemBaseTopValue);
                                    return (
                                        <div className="tickettype-item">
                                            <span className="tickettype-name">{ticketType.name}</span>
                                            <span className="pull-right">
                                                <img src={IconMore} alt="More" className="pull-right"/>
                                            </span>
                                            <div className="options-menu" style={{ top: `${topOffSet}px` }}>
                                                <ul>
                                                    <li onClick={() => this.props.activateShowAndTicketTypeAll(show.id, ticketType.id)}><T _str="Activate ticket type at all"/></li>
                                                    <li onClick={() => this.props.deactivateShowAndTicketTypeAll(show.id, ticketType.id)}><T _str="Deactivate ticket type at all"/></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }
}

Shows.propTypes = {
    shows               : PropTypes.array.isRequired,
    activateShowAndTicketTypeAll   : PropTypes.func.isRequired,
    deactivateShowAndTicketTypeAll : PropTypes.func.isRequired
};

export default Shows;