import React, { Component } from "react";
import { DragDropContext } from "react-dnd";
import { DevicesService } from "./services";
import QRCode from "qrcode.react";
import { T, useT } from "@transifex/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Checkpoint } from "./components/Checkpoint";
import HTML5Backend from "react-dnd-html5-backend";
import { CopyIcon } from "../../../../components/Images";
import Dialog from "../../../../themes/default/Dialog/Dialog";
import { Checkbox, Search } from "../../../../themes/default/Form";
import { CheckpointService } from "../../../../services/api/Checkpoint";
import { HandheldService } from "../../../../services/api/Handheld";
import { VenueService } from "../../../../services/api/Venue";
import { LoadingScreen } from "../../../../components/Loading";
import { DialogError } from "../../../../themes/default/Dialog";
import { ResponseService } from "../../../../services/utils/Response";
import { SceneTitle } from "../../../../themes/default/Title/components/Scene";

import "./style.css";

/**
 * @class scenes/Devices/Devices
 */
class Devices extends Component {
  /**
   * @type {Object}
   */
  checkpointService;

  /**
   * @type {Object}
   */
  devicesService;

  /**
   * @type {Object}
   */
  handheldService;

  /**
   * @type {Object}
   */
  venueService;

  /**
   * @type {String}
   */
  venueId;

  /**
   * @type {Object}
   */
  responseService;

  state = {
    checkpoints: [],
    devices: [],
    devicesToBeAdded: [],
    venueDevices: [],
    venueDevicesCount: 0,
    devicesCount: 0,
    showAvailableDevicesOnly: false,
    deviceSearchFilter: "",
    showDestageDeviceConfirmationDialog: false,
    checkpointName: null,
    deviceId: null,
    showAddDevicesModal: false,
    showLoadingScreen: false,
    showErrorDialog: false,
    errorMessages: [],
    showQRCodeModal: false,
    enrollmentUrl: null,
    isQRCodeCopied: false,
  };

  /**
   * Constructor.
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.venueId = this.props.match.params.id;
    this.checkpointService = new CheckpointService();
    this.devicesService = new DevicesService();
    this.handheldService = new HandheldService();
    this.venueService = new VenueService();
    this.responseService = new ResponseService();
  }

  /**
   * @override
   */
  componentDidMount() {
    this.getCheckpoints();
    this.getDevices();
    this.getDevicesAssignedToVenue();
  }

  /**
   * Gets devices list belong to venue.
   */
  getDevicesAssignedToVenue() {
    let filter = "stagingStatus!=destaged";
    let sort = "name";
    let parameterObject = {
      Filter: filter,
      Sort: sort,
      PageSize: 9999,
    };
    this.setState({
      showLoadingScreen: true,
    });

    setTimeout(() => {
      this.venueService
        .getAllHandhelds(this.venueId, parameterObject)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.results) {
              this.setState({
                venueDevices: response.data.results,
                venueDevicesCount: response.data.resultCount,
              });
            }
          } else {
            this.setState({
              showErrorDialog: true,
              errorMessages: this.responseService.getErrorMessages(
                response.data
              ),
            });
          }
          this.setState({
            showLoadingScreen: false,
          });
        });
    }, 500);
  }

  /**
   * Updates device configuration and change checkpoint where it belongs.
   *
   * @param deviceId
   * @param checkpointName
   */
  deviceDropHandler = (deviceId, checkpointName) => {
    let deviceConfiguration = {
      checkpoint: checkpointName,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService
      .updateConfiguration(deviceId, deviceConfiguration)
      .then((response) => {
        if (response.status === 204) {
          this.getDevicesAssignedToVenue();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Destages device from venue.
   *
   * @param deviceId
   */
  deviceDestageHandler = (deviceId) => {
    let venue = {
      venue: null,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService
      .assignOrUnassignFromVenue(deviceId, venue)
      .then((response) => {
        if (response.status === 204) {
          this.getDevicesAssignedToVenue();
          this.closeDestageDeviceConfirmationDialog();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Gets all checkpoints.
   *
   * @return {Array}
   */
  getCheckpoints = () => {
    this.setState({
      showLoadingScreen: true,
    });
    this.checkpointService.getAll(this.venueId).then((response) => {
      if (response.status === 200) {
        if (response.data.results) {
          this.setState({
            checkpoints: response.data.results,
          });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Gets devices list.
   *
   * @return {Array}
   */
  getDevices = () => {
    let filter = "stagingStatus==destaged";
    let sort = "name";
    let parameterObject = {
      Filter: filter,
      Sort: sort,
      PageSize: 9999,
    };
    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService.getAll(parameterObject).then((response) => {
      if (response.status === 200) {
        if (response.data.results) {
          this.setState({
            devices: response.data.results,
            devicesCount: response.data.resultCount,
          });
        }
      } else {
        this.setState({
          showErrorDialog: true,
          errorMessages: this.responseService.getErrorMessages(response.data),
        });
      }
      this.setState({
        showLoadingScreen: false,
      });
    });
  };

  /**
   * Handles checkbox value.
   *
   * @param {String} value
   * @param {String} name
   */
  getCheckboxValue = (value, name) => {
    const { devicesToBeAdded } = this.state;

    if (name === "show_available_only") {
      this.setState({
        showAvailableDevicesOnly: value,
      });
    } else if (name && name.includes("handheld")) {
      let devicesToBeAdd = [];
      if (value === true) {
        devicesToBeAdd = devicesToBeAdded
          ? [...devicesToBeAdded, name]
          : [name];

        this.setState({
          devicesToBeAdded: devicesToBeAdd,
        });
      } else {
        devicesToBeAdd = devicesToBeAdded.filter(function (e) {
          return e !== name;
        });

        this.setState({
          devicesToBeAdded: devicesToBeAdd,
        });
      }
    }
  };

  /**
   * Generates device label for checkbox.
   *
   * @param {String} device
   * @param {Boolean} inUseOnSelectedCheckpoint
   *
   * @returns {String}
   */
  getVenueDeviceLabel = (device, inUseOnSelectedCheckpoint = false) => {
    let status = "";

    switch (device.stagingStatus) {
      case "destagingRequest":
        status = " destaging";
        break;
      case "stagingRequest":
        status = " staging";
        break;
      case "staged":
        status = inUseOnSelectedCheckpoint
          ? " in use on this checkpoint"
          : " in use";
        break;
      default:
        status = "";
    }

    return device.name + status;
  };

  /**
   * Returns list of all devices or only available depends on filter.
   *
   * @returns {any[]}
   */
  getDevicesList = () => {
    const { devices, deviceSearchFilter, showAvailableDevicesOnly } =
      this.state;

    return devices.map((device, key) => {
      let label =
        device.name + `${device.stagingStatus !== "destaged" ? " in use" : ""}`;
      let checkbox = (
        <Checkbox
          label={label}
          name={device.id}
          getValue={this.getCheckboxValue}
          checked={this.isDeviceSelected(device.id)}
          placeholder="device-id"
          disabled={device.stagingStatus !== "destaged"}
          key={key}
        />
      );
      let showDevice = true;

      if (deviceSearchFilter) {
        showDevice = label.includes(deviceSearchFilter);
      }

      if (showAvailableDevicesOnly) {
        if (deviceSearchFilter) {
          showDevice =
            device.stagingStatus === "destaged" &&
            label.includes(deviceSearchFilter);
        } else {
          showDevice = device.stagingStatus === "destaged";
        }
      }

      return showDevice ? checkbox : null;
    });
  };

  /**
   * Looks for deviceId in devices array.
   *
   * @param {String} deviceId
   *
   * @returns {Boolean}
   */
  isDeviceSelected = (deviceId) => {
    return !!this.state.devicesToBeAdded.find(function (e) {
      return e === deviceId;
    });
  };

  /**
   * Returns list of all devices or only available depends on filter.
   *
   * @param {String} stagingStatus
   *
   * @returns {any[]}
   */
  getVenueDevicesList = (stagingStatus = "staged") => {
    const {
      venueDevices,
      checkpointName,
      deviceSearchFilter,
      showAvailableDevicesOnly,
    } = this.state;

    return venueDevices.map((device, key) => {
      let isInUseOnThisCheckpoint = this.devicesService.isDeviceOnCheckpoint(
        device,
        checkpointName
      );
      let label = this.getVenueDeviceLabel(device, isInUseOnThisCheckpoint);
      let checkbox = (
        <Checkbox
          label={label}
          name={device.id}
          getValue={this.getCheckboxValue}
          checked={this.isDeviceSelected(device.id)}
          placeholder="list-item"
          disabled={
            device.stagingStatus !== stagingStatus || isInUseOnThisCheckpoint
          }
          key={key}
        />
      );
      let showDevice = true;

      if (deviceSearchFilter) {
        showDevice = label.includes(deviceSearchFilter);
      }

      if (showAvailableDevicesOnly) {
        if (deviceSearchFilter) {
          showDevice =
            device.stagingStatus === stagingStatus &&
            label.includes(deviceSearchFilter) &&
            !isInUseOnThisCheckpoint;
        } else {
          showDevice =
            device.stagingStatus === stagingStatus && !isInUseOnThisCheckpoint;
        }
      }

      return showDevice ? checkbox : null;
    });
  };

  /**
   * Assigns device to venue and chosen checkpoint
   *
   * @param {String} deviceId
   * @param {String} checkpoint
   */
  addDeviceToVenue = (deviceId, checkpoint) => {
    let venue = {
      venue: this.venueId,
      checkpoint: checkpoint,
    };

    this.setState({
      showLoadingScreen: true,
    });
    this.handheldService
      .assignOrUnassignFromVenue(deviceId, venue)
      .then((response) => {
        if (response.status === 204) {
          this.getDevicesAssignedToVenue();
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
        this.setState({
          showLoadingScreen: false,
        });
      });
  };

  /**
   * Handles adding devices to checkpoint.
   */
  addDeviceButtonHandler = () => {
    const { devicesToBeAdded, venueDevices, checkpointName } = this.state;

    devicesToBeAdded &&
      devicesToBeAdded.map((deviceId) => {
        let stagedDevice = venueDevices.filter((device) => {
          return deviceId === device.id;
        });

        if (stagedDevice.length > 0) {
          this.deviceDropHandler(deviceId, checkpointName);
        } else {
          this.addDeviceToVenue(deviceId, checkpointName);
        }

        return true;
      });

    this.setState({
      devicesToBeAdded: [],
      deviceSearchFilter: "",
      showAvailableDevicesOnly: false,
      showAddDevicesModal: false,
    });
  };

  /**
   * Calls the access api to get the enrollment url
   */
  setQRCodeButtonHandler = () => {
    const { checkpointName } = this.state;
    const request = {
      venueName: this.props.venue.name,
      venueId: this.props.venue.id,
      checkpointName: checkpointName,
    };
    this.handheldService
      .getEnrollmentAndStagingUrl(request)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            enrollmentUrl: response.data.enrollmentUrl,
            enrollmentCode: response.data.enrollmentCode,
          });
        } else {
          this.setState({
            showErrorDialog: true,
            errorMessages: this.responseService.getErrorMessages(response.data),
          });
        }
      });
  };

  /**
   * Close QR-Code modal.
   *
   */
  closeQRCodeModal = () =>
    this.setState({
      showQRCodeModal: false,
      checkpointName: null,
      enrollmentUrl: null,
      isQRCodeCopied: false,
    });

  /**
   * Renders checkpoint related to venue.
   *
   * @param checkpoint
   * @param key
   * @returns {XML}
   */
  renderCheckpoint = (checkpoint, key) => (
    <Checkpoint
      key={key}
      data={checkpoint}
      devices={this.state.venueDevices}
      handleDeviceDrop={this.deviceDropHandler}
      handleAddDevicesButtonClick={(checkpointName) =>
        this.setState({
          showAddDevicesModal: true,
          checkpointName: checkpointName,
        })
      }
      deviceDestageHandler={(deviceId) =>
        this.setState({
          showDestageDeviceConfirmationDialog: true,
          deviceId: deviceId,
        })
      }
      handleQRCodeButtonClick={(checkpointName) => {
        this.setState(
          {
            showQRCodeModal: true,
            checkpointName: checkpointName,
          },
          () => this.setQRCodeButtonHandler()
        );
      }}
    />
  );

  /**
   * Updates state and closes destage device confirmation dialog.
   */
  closeDestageDeviceConfirmationDialog = () => {
    this.setState({
      showDestageDeviceConfirmationDialog: false,
      deviceId: null,
    });
  };

  /**
   * @returns {XML}
   */
  render() {
    const {
      venueDevicesCount,
      checkpoints,
      showDestageDeviceConfirmationDialog,
      deviceId,
      showAddDevicesModal,
      showQRCodeModal,
      devicesToBeAdded,
      deviceSearchFilter,
      showAvailableDevicesOnly,
      showLoadingScreen,
      showErrorDialog,
      errorMessages,
      devicesCount,
      enrollmentUrl,
      enrollmentCode,
      isQRCodeCopied,
    } = this.state;

    return (
      <div className="devices">
        <Tite
          name={this.props.venue?.name}
          venueDevicesCount={venueDevicesCount}
        />

        <p>
          <T _str="Select devices for the checkpoint. Drop the between checkpoints" />
          .
        </p>
        {checkpoints &&
          checkpoints.map((checkpoint, key) =>
            this.renderCheckpoint(checkpoint, key)
          )}

        {/* confirmation dialog. */}
        {showDestageDeviceConfirmationDialog && (
          <Dialog
            title={<T _str="Confirmation dialog" />}
            showModal={showDestageDeviceConfirmationDialog}
            action={() => this.deviceDestageHandler(deviceId)}
            closeModal={this.closeDestageDeviceConfirmationDialog}
            mainButton={<T _str="Confirm" />}
          >
            <h4 className="text-center">
              <T _str="Are you sure you want to destage this device?" />
            </h4>
          </Dialog>
        )}

        {/* Shows dialog with destaged devices. */}
        {showAddDevicesModal && (
          <Dialog
            mainButton={<T _str="add" />}
            title={<T _str="Add devices" />}
            showModal={showAddDevicesModal}
            additionalTitle={`(${devicesCount})`}
            mainButtonDisabled={devicesToBeAdded.length === 0}
            closeModal={() =>
              this.setState({
                devicesToBeAdded: [],
                deviceSearchFilter: "",
                showAvailableDevicesOnly: false,
                showAddDevicesModal: false,
                checkpointName: null,
              })
            }
            action={this.addDeviceButtonHandler}
          >
            <CheckboxTranslatorWrapper
              value={deviceSearchFilter}
              getValue={this.getCheckboxValue}
              checked={showAvailableDevicesOnly}
            />

            <br />
            <br />

            <SearchTranslatorWrapper
              getValue={(value) => this.setState({ deviceSearchFilter: value })}
            />

            <div id="device_list">
              {this.getDevicesList()}
              {this.getVenueDevicesList()}
            </div>
          </Dialog>
        )}

        {/* Shows dialog with QR-Code to enroll devices. */}
        {showQRCodeModal && (
          <Dialog
            mainButton={<T _str="done" />}
            title={
              <T
                _str='Use this QR-Code to enroll and stage a device into checkpoint: "{checkpointName}"'
                checkpointName={this.state.checkpointName}
              />
            }
            showModal={showQRCodeModal}
            closeModal={this.closeQRCodeModal}
            action={this.closeQRCodeModal}
          >
            <div className="QRCodeDiv text-center">
              <div>
                {/* Renders QR-Code only when the enrollmentUrl is available */}
                {this.state.enrollmentUrl && (
                  <>
                    <QRCode size={256} value={this.state.enrollmentUrl} />
                    {this.state.enrollmentCode && (
                      <div className="mt-2 qrcode-text">
                        {this.state.enrollmentCode}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-2 mb-1 copy-device-QRCode">
              <CopyToClipboard
                onCopy={() => this.setState({ isQRCodeCopied: true })}
                text={enrollmentUrl}
              >
                <span>
                  Copy QRCode <CopyIcon />
                </span>
              </CopyToClipboard>
              {isQRCodeCopied && (
                <div className="mt-1 copied">
                  <T _str="QRCode copied successfully" />
                </div>
              )}
            </div>
          </Dialog>
        )}

        {showLoadingScreen && <LoadingScreen />}

        {/* Renders error diagram if an error occur. */}
        <DialogError
          show={showErrorDialog}
          closeDialog={() => this.setState({ showErrorDialog: false })}
        >
          {errorMessages.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </DialogError>
      </div>
    );
  }
}

const Tite = ({ name, venueDevicesCount }) => {
  const t = useT();
  const devices = t("Devices");
  const text = `${devices} ${venueDevicesCount ? venueDevicesCount : ""}`;
  return (
    <SceneTitle text={text}>
      <p>{name}</p>
    </SceneTitle>
  );
};

const CheckboxTranslatorWrapper = ({ value, getValue, checked }) => {
  const t = useT();
  return (
    <Checkbox
      value={value}
      checked={checked}
      getValue={getValue}
      name="show_available_only"
      label={t("show available only")}
      placeholder={t("show_available_only")}
    />
  );
};

const SearchTranslatorWrapper = ({ getValue }) => {
  const t = useT();
  return (
    <Search
      name="search_devices"
      placeholder={t("Search")}
      getValue={getValue}
    />
  );
};

export default DragDropContext(HTML5Backend)(Devices);
