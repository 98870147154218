import axios from 'axios/index';

/**
 * @class ./services/api/Show/Show
 */
class Show
{

    /**
     * Returns paginates list of all shows.
     *
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *   {
     *    "id": "string",
     *    "externalId": "string",
     *    "name": "string",
     *    "begin": "2018-03-01T10:56:54.884Z",
     *    "end": "2018-03-01T10:56:54.884Z",
     *    "ticketUsageLimit": 0,
     *    "allowReentryAfter": "string"
     *   }
     *  ],
     *   "resultCount": 0,
     *   "totalResultCount": 0,
     *   "pageSize": 0,
     *   "currentPage": 0,
     *   "totalPageCount": 0
     * }
     *
     */
    getAll = (paramObject) =>
    {
        return axios.get('/shows',
            {
                params: paramObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Creates one show.
     *
     * @param {Object} show
     *
     * @returns {Object}
     *
     * @example Request/Response:
     *   {
     *    "id": "string",
     *    "externalId": "string",
     *    "name": "string",
     *    "begin": "2018-03-01T10:56:54.884Z",
     *    "end": "2018-03-01T10:56:54.884Z",
     *    "ticketUsageLimit": 0,
     *    "allowReentryAfter": "string",
     *    "customProperties": {
     *       "additionalProp1": "string",
     *       "additionalProp2": "string",
     *       "additionalProp3": "string"
     *     },
     *    "filterableCustomTicketProperties": [
     *      "string"
     *     ]
     *   }
     *
     */
    createOne = (show) =>
    {
        return axios.post('/shows',
            show
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns one show based on show Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     * @example Response:
     *   {
     *    "id": "string",
     *    "externalId": "string",
     *    "name": "string",
     *    "begin": "2018-03-01T10:56:54.884Z",
     *    "end": "2018-03-01T10:56:54.884Z",
     *    "ticketUsageLimit": 0,
     *    "allowReentryAfter": "string",
     *    "customProperties": {
     *       "additionalProp1": "string",
     *       "additionalProp2": "string",
     *       "additionalProp3": "string"
     *     },
     *    "filterableCustomTicketProperties": [
     *      "string"
     *     ]
     *   }
     *
     */
    getOne = (id) =>
    {
        return axios.get('/shows/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one show based on show Id.
     *
     * @param {String} id
     * @param {Object} show
     *
     * @returns {Object}
     *
     * @example Request:
     *   {
     *    "id": "string",
     *    "externalId": "string",
     *    "name": "string",
     *    "begin": "2018-03-01T10:56:54.884Z",
     *    "end": "2018-03-01T10:56:54.884Z",
     *    "ticketUsageLimit": 0,
     *    "allowReentryAfter": "string",
     *    "customProperties": {
     *       "additionalProp1": "string",
     *       "additionalProp2": "string",
     *       "additionalProp3": "string"
     *     },
     *    "filterableCustomTicketProperties": [
     *      "string"
     *     ]
     *   }
     *
     */
    updateOne = (id, show) =>
    {
        return axios.put('/shows/' + encodeURIComponent(id),
            show
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Updates one show based on show Id.
     *
     * @param {String} id
     * @param {Object} show
     *
     * @returns {Object}
     *
     * @example Request:
     *   {
     *    "barcodeSymbologies": [
     *      "string"
     *     ]
     *   }
     *
     */
    patchOne = (id, show) =>
    {
        return axios.patch('/shows/' + encodeURIComponent(id),
            show
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };
    
    /**
     * Deletes one show based on show Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     */
    deleteOne = (id) =>
    {
        return axios.delete('/shows/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Force updating the barcode for a certain show 
     *
     * @param {String} id
     *
     */
    importBarcodes = async (id) => {
        try {
            const response = await axios.post('/shows/' + encodeURIComponent(id) + '/tickets/import?force=true');
            return {
                status: response.status,
                data: response.data
            };
        } catch (error) {
            return {
                status: error.response.status,
                data: error.response.data
            };
        }
    };

    getScanLogs = async (id, csv = true) => {
        try {
            const headers = {
                Accept: csv ? 'text/csv' : 'application/json',
              };

            const response = await axios.get('/shows/' + encodeURIComponent(id) + '/scanlogs', {
                responseType: "Blob",
                headers: headers,
            });
            return {
                status: response.status,
                data: response.data
            };
        } catch (error) {
            return {
                status: error.response.status,
                data: error.response.data
            };
        }
    };
}

export default Show;