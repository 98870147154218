import axios from 'axios/index';

/**
 * @class ./services/api/Handheld/Handheld
 */
class Handheld
{

    /**
     * Returns paginates list of all handhelds.
     *
     * @param {Object} paramObject
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *  "results": [
     *    {
     *      "id": "string",
     *      "venueId": "string",
     *      "name": "string",
     *      "deviceModel": "string",
     *      "stagingStatus": "destaged",
     *      "state": {
     *        "lastStatusReport": "2018-03-01T10:56:54.838Z",
     *        "batteryLevel": 0,
     *        "appVersion": "1.0.0",
     *        "wifiSignal": 0,
     *        "mobileSignal": 0
     *       }
     *     }
     *  ],
     *  "resultCount": 0,
     *  "totalResultCount": 0,
     *  "pageSize": 0,
     *  "currentPage": 0,
     *  "totalPageCount": 0
     * }
     *
     */
    getAll = (paramObject) =>
    {
        return axios.get('/handhelds',
            {
                params: paramObject
            })
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Returns one handheld based on handheld Id.
     *
     * @param {String} id
     *
     * @returns {Object}
     *
     * @example Response:
     * {
     *    {
     *      "id": "string",
     *      "venueId": "string",
     *      "name": "string",
     *      "deviceUniqueIdentifier": "string",
     *      "deviceModel": "string",
     *      "stagingStatus": "destaged",
     *      "state": {
     *        "activeTicketTypes" {
     *           "additionalProp1": [ 
     *              {
     *                 "id": "string",
     *                 "name": "string",
     *                 "faceValue": 0,
     *                 "childrenTicketTypes": []
     *              },
     *              {
     *                 "id": "string",
     *                 "name": "string",
     *                 "faceValue": 0,
     *                 "childrenTicketTypes": []
     *              }
     *           ],
     *           "additionalProp2": [ 
     *              {
     *                 "id": "string",
     *                 "name": "string",
     *                 "faceValue": 0,
     *                 "childrenTicketTypes": []
     *              },
     *              {
     *                 "id": "string",
     *                 "name": "string",
     *                 "faceValue": 0,
     *                 "childrenTicketTypes": []
     *              }
     *           ]
     *        },
     *        "lastStatusReport": "2018-03-01T10:56:54.838Z",
     *        "batteryLevel": 0,
     *        "appVersion": "1.1.0",
     *        "wifiSignal": 0,
     *        "mobileSignal": 0,
     *        "volume": {
     *          "reportedValue": 0,
     *          "adminRequestedValue": 0,
     *          "latestAdminValueApplied": true
     *        },
     *        "brightness": {
     *         "reportedValue": 0,
     *          "adminRequestedValue": 0,
     *          "latestAdminValueApplied": true
     *        },
     *        "checkpoint": {
     *          "reportedValue": "string",
     *          "adminRequestedValue": "string",
     *          "latestAdminValueApplied": true
     *        },
     *        "shows": {
     *           "additionalProp1": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *           },
     *           "additionalProp2": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *           },
     *           "additionalProp3": {
     *               "reportedValue": true,
     *               "adminRequestedValue": true,
     *               "latestAdminValueApplied": true
     *          }
     *       }
     *    }
     * }
     *
     */
    getOne = (id) =>
    {
        return axios.get('/handhelds/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                };
            })
            .catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Updates one handheld based on handheld Id.
     *
     * @param {String} id
     * @param {Object} handheld
     *
     * @example Request:
     *  {
     *    "name": "string",
     *    "deviceModel": "string"
     *  }
     *
     */
    updateOne = (id, handheld) =>
    {
        return axios.put('/handhelds/' + encodeURIComponent(id),
            handheld
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Deletes one handheld based on handheld Id.
     *
     * @param {String} id
     *
     */
    deleteOne = (id) =>
    {
        return axios.delete('/handhelds/' + encodeURIComponent(id))
            .then(response => {
                return {
                    status: response.status,
                    data: response.data
                }
            }).catch(error => {
                    return {
                        status: error.response.status,
                        data: error.response.data
                    };
                }
            );
    };

    /**
     * Returns an enrollment code that can be used to register new devices on the backend.
     * Every call to this method will generate a new code that is valid for 10 minutes.
     *
     * @param {Object} handheld
     *
     * @returns {Object}
     *
     * @example Response:
     *  {
     *   "enrollmentCode": "string",
     *   "validUntil": "2018-03-01T10:56:54.866Z"
     *  }
     *
     */
    getEnrollmentCode = (handheld) =>
    {
        return axios.post('/handhelds/enroll',
             handheld
            ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        })
            .catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Returns an enrollment url that will enroll and stage the device to the specified venue and checkpoint in the request.
     * 
     * @param {Object} request 
     * @returns {Object}
     * 
     * @example Request:
     *  {
     *   "venueName": "Venue123",
     *   "venueId": "venue::c042884c-402a-4a18-828f-79a950ed2f72",
     *   "checkpointName": "Main Entry"     *   
     *  }
     * 
     * @example Response:
     *  {
     *   "enrollmentUrl": "string",
     *   "validUntil": "2018-03-01T10:56:54.866Z"
     *  }
     */
    getEnrollmentAndStagingUrl = (request) =>
    {
        return axios.post('/handhelds/enrollandstage',
        request
            ).then(response => {
            return {
                status: response.status,
                data: response.data
            };
        })
            .catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Updates the handheld configuration.
     * Changes are synchronized to the device.
     * Only add the properties to the request that should be modified.
     * To modify checkpoint or active shows and tickettypes the handheld has to be staged.
     *
     * @param {String} id
     * @param {Object} handheld
     *
     * @example Request:
     *  {
     *   "volume": 0,
     *   "brightness": 0,
     *   "checkpoint": "string",
     *   "showActivations": {
     *      "additionalProp1": true,
     *      "additionalProp2": true,
     *      "additionalProp3": true
     *    }
     *   "ticketTypeActivations": {
     *      "additionalProp1": true,
     *      "additionalProp2": true,
     *      "additionalProp3": true
     *    }
     *  }
     *
     */
    updateConfiguration = (id, handheld) =>
    {
        return axios.put('/handhelds/' + encodeURIComponent(id) + '/config',
            handheld
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Assigns the handheld to a venue or unassigns it from the current venue.
     *
     * @param {String} id
     * @param {Object} venue
     *
     * @example Request:
     *  {
     *   "venue": "string"
     *  }
     *
     */
    assignOrUnassignFromVenue = (id, venue) =>
    {
        return axios.post('/handhelds/' + encodeURIComponent(id) + '/staging',
            venue
        ).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

    /**
     * Resets the handheld.
     * This call immediately destages the handheld which might incur data loss as the handhelds
     * will not perform a final data sync.
     *
     * @param {String} id
     *
     */
    reset = (id, deleteBYOD) =>
    {
        return axios.post('/handhelds/' + encodeURIComponent(id) + '/reset', {
            delete: deleteBYOD
        }).then(response => {
            return {
                status: response.status,
                data: response.data
            }
        }).catch(error => {
                return {
                    status: error.response.status,
                    data: error.response.data
                };
            }
        );
    };

}

export default Handheld;